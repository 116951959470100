.search-input-home {
  border-left: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  height: 35px;
  border-radius: 8px;
  line-height: 20px;
  width: 291px !important;
  padding: 10px 5px;
}
.search-input-home:focus {
  border: 1px solid #e9e9e9;
  box-shadow: none;
  border-left: none;
}
.home-search-eye {
  background-color: white;
  border-radius: 8px;

  svg {
    color: #c4c5c9;
    height: 20px;
    cursor: pointer;
  }
}
