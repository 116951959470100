/* src/assets/styles/theme.css */
:root {
  /* Define common colors */
  --primary-color: #101828;
  --secondary-color: #e20816;
  --background-color: #344054;
  --border-color: #d0d5dd;
  --text-color: #101828;

  /* Define fonts */
  --font-primary: "Inter", sans-serif; /* Primary font */
  --font-secondary: "Courier New", monospace; /* Secondary font */
  --font-post: "Mulish", sans-serif;
  /* Define LeftBar */
  --left-drawer-width: 248px;
  --header-height: 75px;

  /* Define other styles */
  --button-padding: 10px 20px;
  --border-radius: 5px;
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  --slider-border-radius: 24px; /* for login page */

  /* shadow */
  --primary-box-shadow: 0 0 0 0.25rem rgb(16 24 40 / 19%);
  --secondary-box-shadow: 0 0 0 0.25rem rgb(226 7 22 / 19%);

  /* buttons */
  --header-btn-height: 37px;

  --mirror-rotate-angle: rotate(0deg);
}
