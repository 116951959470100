.pagination-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  width: calc(97vw - var(--left-drawer-width));
  padding: 20px 0px;
}
.arrow-pg-wrapper {
  padding: 3px 6px;
  border: 1px solid #d0d5dd;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
  .disabled {
    color: #d0d5dd;
    border: 1px solid #d0d5dd;
    svg {
      color: #d0d5dd;
    }
  }
  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
.pagination {
  margin: 0;
  .page-item {
    .page-link {
      margin: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #667085;
      border: none;
      background: none;
      box-shadow: none;
    }
  }
  .page-item.active {
    border: 1px solid var(--secondary-color);
    border-radius: 2px;
    .page-link {
      color: var(--secondary-color) !important;
      box-shadow: none;
    }
    .page-link:focus {
      box-shadow: none;
    }
  }
}
.pagn-go-to {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
}
.pagn-go-to-input {
  width: 48px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid #d0d5dd;
  text-align: center;
}
.pagn-go-to-input:focus {
  box-shadow: none;
  border: 1px solid var(--primary-color);
}
