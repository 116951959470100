.home-table-header th {
  padding: 12px;
  background: #344054;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #eaecf0;
  border: none;
}
.home-table-header th:first-child {
  Input {
    border-color: var(--secondary-color);
    font-size: 15px;
  }
  Input:focus {
    box-shadow: var(--secondary-box-shadow);
  }
  Input[type="checkbox"]:checked {
    background-color: var(--secondary-color);
  }
}

.home-table-body td:first-child {
  Input {
    border-color: var(--primary-color);
    font-size: 15px;
  }
  Input:focus {
    box-shadow: var(--primary-box-shadow);
  }
  Input[type="checkbox"]:checked {
    background-color: var(--primary-color);
  }
}
.home-table-body {
  td {
    border: none;
    border-bottom: 1px solid #eaecf0;
    padding: 12px 24px 12px 24px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 12px;
    svg {
      font-size: 17px;
    }
    a {
      text-decoration: none;
    }
  }
  .badge.bg-warning {
    border-radius: 10px;
    background-color: #fff7de !important;
    font-family: Inter;
    font-size: 10px;
    text-align: center;
    color: #d19d00;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: fit-content;
    .badge-dot-wrapper {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #ffc000;
    }
  }

  .badge.bg-success {
    border-radius: 10px;
    background-color: #ecfdf3 !important;
    font-family: Inter;
    font-size: 10px;
    text-align: center;
    color: #037847;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: fit-content;
    .badge-dot-wrapper {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #14ba6d;
    }
  }

  .badge.bg-danger {
    border-radius: 10px;
    background-color: #ffefee !important;
    font-family: Inter;
    font-size: 10px;
    text-align: center;
    color: #d14321;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: fit-content;
    .badge-dot-wrapper {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #e33c14;
    }
  }
  .badge.bg-info {
    border-radius: 10px;
    background-color: #f2f4f7 !important;
    font-family: Inter;
    font-size: 10px;
    text-align: center;
    color: #364254;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: fit-content;
    .badge-dot-wrapper {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #6c778b;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: green;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
