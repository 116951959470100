.header {
  position: fixed;
  left: var(--left-drawer-width);
  right: 0;
  top: 0;
  height: var(--header-height);
  background-color: white;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 9;
}

.header h1 {
  margin: 0;
  font-size: 1.5em;
}

.header nav {
}

.header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.header nav ul li {
  margin-right: 15px;
}
.header nav ul li:last-child {
  margin-left: 10px;
}
.header nav ul li a {
  text-decoration: none;
  color: #333;
}

.header nav ul li a:hover {
  text-decoration: underline;
}
