.regis-form-wrapper {
  width: 100%;
  height: 100vh;
  align-items: center;
  /* max-width: 1300px; */
  margin: auto;
  padding: 2rem 0rem;
  justify-content: center;
  background-color: white;
}
.regis-left-wrapper {
  text-align: center;
  max-width: 465px;
}
.regis-heading {
  font-family: Inter;
  font-size: 36px;
  font-weight: 800;
  line-height: 48px;
  text-align: center;
  color: #101828;
  margin-bottom: 25px;
}
.regis-logo-wrapper {
  margin-bottom: 30px;
}
.regis-input {
  border: 1px solid #e9e9e9;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 400;
  text-align: left;
  height: 45px;
  border-radius: 8px;
  line-height: 20px;
}
.regis-input:focus {
  border: 1px solid #e9e9e9;
  box-shadow: none;
}
.fade-alert-input {
  padding: 2px;
  color: var(--secondary-color);
  border: none;
  font-size: 13px;
}

.regis-or {
  width: auto;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #171717;
  margin: 0;
}
.regis-info {
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #171717;
}

.regis-right-wrapper {
  width: 100%;
  max-width: 548px;
}
.regis-verify {
  transition: color 0.3s ease-in-out;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  padding: 6px;
  background: white;
}
.regis-verify.verified {
  color: green;
  cursor: auto;
}
.regis-pass-eye {
  background-color: white;

  svg {
    color: grey;
    width: 22px;
    height: 15px;
    cursor: pointer;
  }
}
.otp-verify-msg {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 4px;
}
