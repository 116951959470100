.lft_drwr_wrppr {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--left-drawer-width);
  height: 100%;
  padding: 0px 0px 11px 0px;
  gap: 0px;
  border-right: 1px solid var(--border-color);
  background-color: white;

  .logo_wrapper {
    height: var(--header-height);
    border-bottom: 1px solid var(--border-color);
    font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    img {
      width: 65px;
    }
  }
  .left_menu_wrapper {
    cursor: pointer;
    align-items: center;
    padding: 10px;
    margin: 0px 15px;
    display: flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.51px;
    text-align: center;
    color: #101828;
    gap: 15px;
    text-decoration: none;
    transition: background 0.3s, color 0.3s, border-radius 0.3s;
  }
  .left_menu_wrapper.active {
    background: #f5f5f5;
    color: var(--secondary-color);
    border-radius: 4px;
  }
  .left_menu_wrapper:hover {
    background: #f5f5f5;
    border-radius: 4px;
  }
  .left_menu_ico {
    color: #878787;
  }
  .left_menu_ico.active {
    color: var(--secondary-color);
  }
}
