.add-edit-btn {
  width: auto;
  height: var(--header-btn-height);
  padding: 0 17px;
  gap: 12px;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  border: none;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.51px;
  text-align: center;
  color: #101828;
  position: relative;
}
.calender-btn {
  /* width: 124px; */
  height: 32px;
  display: flex;
  gap: 13px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  background-color: white;
  color: #344054;
}
.calender-btn:hover,
.calender-btn:active,
.calender-btn:focus {
  box-shadow: none;
  border: 1px solid #171717;
}
.reprocess-btn {
  width: 124px;
  height: 32px;
  display: flex;
  gap: 13px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  background-color: #0070ff;
  color: white;
  span svg {
    font-size: 17px;
    transform: scaleX(-1);
  }
}
.right-icon-btn {
  width: 124px;
  height: 32px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  background-color: white;
  color: #344054;
  justify-content: space-between;
  padding: 0px 8px 0px 10px;
}

.btn-dot-top {
  width: 8px;
  height: 8px;
  background-color: var(--secondary-color);
  position: absolute;
  right: 20px;
  top: 17px;
  border-radius: 50%;
  border: 1px solid #f4f5fe;
}
.add-edit-btn:hover {
  background-color: #e8e8e8;
}
.info-btn {
  width: 162px;
  height: 32px;
  border-radius: 8px;
  background: #eaecf0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.regis-create {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background: #101828;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 500;
  line-height: 22.51px;
  text-align: center;
  margin: 20px 0px;
}
.regis-create-asura {
  width: 100%;
  height: 45px;
  border-radius: 4px;
  background: #101828;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 500;
  line-height: 22.51px;
  text-align: center;
  margin: 0px;
}
.asura-btn {
  width: 100%;
  max-width: 276px;
  height: 34px;
  padding: 8px 17px 8px 17px;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  background: #ffffff;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 400;
  line-height: 18.16px;
  color: #171717;
  text-align: center;
}
.asura-btn:hover,
.asura-btn:active,
.asura-btn.active {
  border: 1px solid #101828 !important;
  background: #171717 !important;
  color: #ffffff !important;
}
.switch-button {
  width: 77px;
  height: 34px;
  padding: 8px 17px 8px 17px;
  border: 1px solid #e9e9e9;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  border-radius: 8px !important;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 400;
  line-height: 18.16px;
  text-align: left;
}
.switch-button:hover,
.switch-button:active,
.switch-button:focus,
.switch-button.active {
  background: none !important;
  color: black !important;
  box-shadow: none;
  text-align: center;
  padding: 0;
}
