.created_post_done_row {
  width: 100%;
  height: 200px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 13px;
  font-family: var(--font-primary);
  font-size: 16px;
  margin-top: 3rem;
  .col {
    text-align: center;
    display: contents;
  }

  .done_created_post_wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: white;
    }
  }
}
