.breadcrumb {
  align-items: center;
  gap: 10px;
  .breadcrumb-item {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #101828;
  }
  .breadcrumb-item-back {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 24px 0px #0000001a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    cursor: pointer;
    svg {
      transform: var(--mirror-rotate-angle);
      transition: transform 0.3s ease 0s;
    }
  }
  .breadcrumb-item-date {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #667085;
    padding-top: 4px;
  }
}
