.profile-wrapper {
  .profile-img-wrapper {
    width: var(--header-btn-height);
    height: var(--header-btn-height);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .profile-img-wrapper img {
    width: auto;
    display: block;
    object-fit: cover;
  }
  .profile-info-wrapper {
    justify-content: center;
    display: flex;
    flex-direction: column;
    .profile-info-name {
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: #101010;
    }
    .profile-info-email {
      font-family: Plus Jakarta Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #606060;
    }
  }
}
