.dropdwn-btn-home {
  width: 124px;
  height: 32px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  align-items: center;
  background-color: white;
  color: #344054;
  justify-content: space-between;
  padding: 0px 8px 0px 10px;
}
.dropdwn-btn-home:hover,
.dropdwn-btn-home:active {
  background-color: white !important;
  color: #344054 !important;
}
.dropdwn-home-menu {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
