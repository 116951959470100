.asura-setup-container {
  justify-content: center;
  align-items: center;
  min-height: calc(95vh - var(--header-height));
}
.asura-setup-wrapper {
  width: 632px;
  max-width: 632px;
  padding: 36px;
  gap: 16px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eaecf0;
}
.asura-input {
  border: 1px solid #e9e9e9;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 400;
  text-align: left;
  height: 34px;
  border-radius: 4px;
  line-height: 20px;
  .file {
    border-right: 0px;
  }
}
.asura-input-file {
  border: 1px solid #e9e9e9;
  border-right: 0px;
  font-family: Inter;
  font-size: 15.01px;
  font-weight: 400;
  text-align: left;
  height: 34px;
  border-radius: 4px;
  line-height: 20px;
}
.asura-input:focus {
  border: 1px solid #e9e9e9;
  box-shadow: none;
}
.asura-labe {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #171717;
}
.alert_asura {
  position: absolute;
  top: var(--header-height);
  right: 0px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background-color: white;
  z-index: 5;
}
.file-wrapper-asura {
  position: absolute;
  left: 0;
}
.asura-ico-wrapper {
  background: none;
  padding: 0 6px;
  border-left: 0px;
  border-radius: 4px;
  border: 1px solid #e9e9e9;

  img {
    width: 100%;
  }
}
