.slider-container {
  position: relative;
  overflow: hidden;
  border-radius: var(--slider-border-radius);
  height: 640px;
}
.shadow-add {
  background: linear-gradient(360deg, #171717 0%, rgba(23, 23, 23, 0) 25.65%);
  position: absolute;
  bottom: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.slider {
  display: flex;
}

.slide {
  flex: 0 0 100%;
  height: 772px;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s;
  border-radius: 19px;
  max-height: 640px;
  border-radius: var(--slider-border-radius);
}

.active {
  display: block;
}

.image {
  width: 100%;
  height: auto;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: black;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.dots-slider {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  bottom: 30px;
  left: 45%;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #ffffff33;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: white;
}
