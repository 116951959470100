.homePage-top-menu {
  display: flex;
  justify-content: space-between;
  padding: 9px 20px;
  background-color: white;
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin-right: 10px;
    }
  }
}
