.chat-wrapper {
  max-width: calc(var(--left-drawer-width) - 32px);
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  margin: auto;
  position: fixed;
  bottom: 15px;
  left: 15px;
  text-align: center;
  background-color: white;
}
.chat-input {
  border: 1px solid #e9e9e9;
  font-family: Inter;
  font-weight: 400;
  text-align: left;
  height: 40px;
  border-radius: 8px;
  line-height: 20px;
  font-size: 12px;
  line-height: 14.52px;
  color: #17171766;
}
.chat-input:focus {
  border: 1px solid #e9e9e9;
  box-shadow: none;
}
.chat-logo-wrapper {
  margin-bottom: 10px;

  img {
    width: 33px;
  }
}
.chat-btn {
  position: absolute;
  bottom: 40px;
  right: 27px;
  display: inline-block;
  transform: translateY(-3px) rotate(-40deg); /* Adjust translateY and rotate values as needed */
}
